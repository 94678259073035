import React, { Component } from 'react'
import { StyleSheet, Text, ScrollView, TouchableOpacity, View} from 'react-native'

const styles = StyleSheet.create({
    header: {
        fontSize:16,
        fontWeight:'bold',
        textAlign:'center', 
        margin:5
    },
    text: {
        fontSize: 12,
        margin:5
    }
})

export default class PrivacyPolicy extends Component {
    render () {
        const {onExit} = this.props
        return (
            <View style={{flex:1}}>
                <View
                    style={{marginTop:10, flexDirection:'row', flexWrap:'wrap'}}
                    onPress={() => onExit()}
                >
                    <TouchableOpacity                
                        onPress={() => { 
                            onExit();
                        }}
                    >
                        <Text style={{color:'white', fontWeight:'bold', fontSize:20, margin:10}}>Back</Text>
                    </TouchableOpacity>
                </View>
                <ScrollView contentContainerStyle={{margin:10, paddingBottom:20, backgroundColor:'white'}}>

                <Text style={{fontSize:18, fontWeight:'bold', textAlign:'center', margin:5}}>
                        Privacy Policy
                    </Text>

                    <Text style={styles.text}> 
                    Effective date: October 15, 2019 {"\n"}
{"\n"}
Prosper Street LLC ("us", "we", or "our") operates the The Reco App mobile
application (hereinafter referred to as the "Service"). {"\n"}
{"\n"}
This page informs you of our policies regarding the collection, use and
disclosure of personal data when you use our Service and the choices you have
associated with that data. {"\n"}
{"\n"}
We use your data to provide and improve the Service. By using the Service, you
agree to the collection and use of information in accordance with this policy.
Unless otherwise defined in this Privacy Policy, the terms used in this
Privacy Policy have the same meanings as in our Terms and Conditions. {"\n"}
                    </Text>

                    <Text style={styles.header}>
                        Definitions 
                    </Text>

                    <Text style={styles.text}>
                    * Service {"\n"}
{"\n"}
Service is the The Reco App mobile application operated by Prosper Street
LLC {"\n"}
{"\n"}
* Personal Data {"\n"}
{"\n"}
Personal Data means data about a living individual who can be identified
from those data (or from those and other information either in our
possession or likely to come into our possession). {"\n"}
{"\n"}
* Usage Data {"\n"}
{"\n"}
Usage Data is data collected automatically either generated by the use of
the Service or from the Service infrastructure itself (for example, the
duration of a page visit). {"\n"}
{"\n"}
* Cookies{"\n"}
{"\n"}
Cookies are small files stored on your device (computer or mobile device). {"\n"}
{"\n"}
* Data Controller {"\n"}
{"\n"}
Data Controller means the natural or legal person who (either alone or
jointly or in common with other persons) determines the purposes for which
and the manner in which any personal information are, or are to be,
processed.{"\n"}
{"\n"}
For the purpose of this Privacy Policy, we are a Data Controller of your
Personal Data. {"\n"}
{"\n"}
* Data Processors (or Service Providers) {"\n"}
{"\n"}
Data Processor (or Service Provider) means any natural or legal person who
processes the data on behalf of the Data Controller. {"\n"}
{"\n"}
We may use the services of various Service Providers in order to process
your data more effectively. {"\n"}
{"\n"}
* Data Subject (or User) {"\n"}
{"\n"}
Data Subject is any living individual who is using our Service and is the
subject of Personal Data.{"\n"}
                    </Text>

                    <Text style={styles.header}>
                        Information Collection and Use
                    </Text>

                    <Text style={styles.text}>
We collect several different types of information for various purposes to
provide and improve our Service to you.                    
                    </Text>

                    <Text style={{...styles.text, fontWeight:"bold"}}>
                        Types of Data Collected:
                    </Text>

                    <Text style={styles.text}>      
                    Personal Data{"\n"}  
{"\n"}
While using our Service, we may ask you to provide us with certain personally
identifiable information that can be used to contact or identify you
("Personal Data"). Personally identifiable information may include, but is not
limited to:{"\n"}
{"\n"}
  {"   "}* Email address{"\n"}
  {"   "}* First name and last name{"\n"}
  {"   "}* Usage Data{"\n"}
{"\n"}
Usage Data  {"\n"}
{"\n"}
When you access the Service with a mobile device, we may collect certain
information automatically, including, but not limited to, the type of mobile
device you use, your mobile device unique ID, the IP address of your mobile
device, your mobile operating system, the type of mobile Internet browser you
use, unique device identifiers and other diagnostic data ("Usage Data").{"\n"}
{"\n"}
Location Data  {"\n"}
{"\n"}
We may use and store information about your location if you give us permission
to do so ("Location Data"). We use this data to provide features of our
Service, to improve and customise our Service.{"\n"}
{"\n"}
You can enable or disable location services when you use our Service at any
time by way of your device settings.{"\n"}
{"\n"}
                    </Text>

                    <Text style={styles.header}>
                    Use of Data
                    </Text>

                    <Text style={styles.text}>
Prosper Street LLC uses the collected data for various purposes:{"\n"}
{"\n"}
{"   "}* To provide and maintain our Service{"\n"}
{"   "}* To notify you about changes to our Service{"\n"}
{"   "}* To allow you to participate in interactive features of our Service when you choose to do so{"\n"}
{"   "}* To provide customer support{"\n"}
{"   "}* To gather analysis or valuable information so that we can improve our Service{"\n"}
{"   "}* To monitor the usage of our Service{"\n"}
{"   "}* To detect, prevent and address technical issues{"\n"}
                    </Text>

                    <Text style={styles.header}>
                    Legal Basis for Processing Personal Data under the General Data Protection Regulation (GDPR)
                    </Text>

                    <Text style={styles.text}>
If you are from the European Economic Area (EEA), Prosper Street LLC legal basis for collecting and 
using the personal information described in this Privacy Policy depends on the Personal Data we 
collect and the specific context in which we collect it.{"\n"}
{"\n"}
Prosper Street LLC may process your Personal Data because:{"\n"}
{"\n"}
{"   "}* We need to perform a contract with you{"\n"}
{"   "}* You have given us permission to do so{"\n"}
{"   "}* The processing is in our legitimate interests and it is not overridden by your rights{"\n"}
{"   "}* To comply with the law{"\n"}
                    </Text>

                    <Text style={styles.header}>
                    Retention of Data
                    </Text>

                    <Text style={styles.text}>
Prosper Street LLC will retain your Personal Data only for as long as is necessary for the purposes 
set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary 
to comply with our legal obligations (for example, if we are required to retain your data to comply 
with applicable laws), resolve disputes and enforce our legal agreements and policies.{"\n"}
{"\n"}
Prosper Street LLC will also retain Usage Data for internal analysis purposes. Usage Data is generally 
retained for a shorter period of time, except when this data is used to strengthen the security or to 
improve the functionality of our Service, or we are legally obligated to retain this data for longer
periods.{"\n"}
                    </Text>

                    <Text style={styles.header}>
                    Transfer of Data
                    </Text>

                    <Text style={styles.text}>
Your information, including Personal Data, may be transferred to - and maintained on - computers 
located outside of your state, province, country or other governmental jurisdiction where the data 
protection laws may differ from those of your jurisdiction.{"\n"}
{"\n"}
If you are located outside United States and choose to provide information to us, please note that we 
transfer the data, including Personal Data, to United States and process it there.{"\n"}
{"\n"}
Your consent to this Privacy Policy followed by your submission of such information represents your 
agreement to that transfer.{"\n"}
{"\n"}
Prosper Street LLC will take all the steps reasonably necessary to ensure that your data is treated 
securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take 
place to an organisation or a country unless there are adequate controls in place including the security 
of your data and other personal information.{"\n"}
                    </Text>

                    <Text style={styles.header}>
                    Disclosure of Data
                    </Text>

                    <Text style={styles.text}>
<Text style={{...styles.text, fontWeight:"bold"}}>Business Transaction{"\n"}</Text>
If Prosper Street LLC is involved in a merger, acquisition or asset sale, your Personal Data may be 
transferred. We will provide notice before your Personal Data is transferred and becomes subject to a 
different Privacy Policy.{"\n"}
{"\n"}
<Text style={{...styles.text, fontWeight:"bold"}}>Disclosure for Law Enforcement{"\n"}</Text>
Under certain circumstances, Prosper Street LLC may be required to disclose your Personal Data if 
required to do so by law or in response to valid requests by public authorities (e.g. a court or a 
government agency).{"\n"}
{"\n"}
<Text style={{...styles.text, fontWeight:"bold"}}>Legal Requirements{"\n"}</Text>
Prosper Street LLC may disclose your Personal Data in the good faith belief that such action is 
necessary to:{"\n"}
{"\n"}
To comply with a legal obligation{"\n"}
To protect and defend the rights or property of Prosper Street LLC{"\n"}
To prevent or investigate possible wrongdoing in connection with the Service{"\n"}
To protect the personal safety of users of the Service or the public{"\n"}
To protect against legal liability{"\n"}
                    </Text>

                    <Text style={styles.header}>
                    Security of Data
                    </Text>

                    <Text style={styles.text}>
The security of your data is important to us but remember that no method of transmission over the 
Internet or method of electronic storage is 100% secure. While we strive to use commercially 
acceptable means to protect your Personal Data, we cannot guarantee its absolute security.{"\n"}
                    </Text>

                    <Text style={styles.header}>
                    Our Policy on "Do Not Track" Signals under the California Online Protection Act (CalOPPA)
                    </Text>

                    <Text style={styles.text}>
We do not support Do Not Track ("DNT"). Do Not Track is a preference you can set in your web browser 
to inform websites that you do not want to be tracked.{"\n"}
{"\n"}
You can enable or disable Do Not Track by visiting the Preferences or Settings page of your web browser.{"\n"}
                    </Text>

                    <Text style={styles.header}>
                    Your Data Protection Rights under the General Data Protection Regulation (GDPR)
                    </Text>

                    <Text style={styles.text}>
If you are a resident of the European Economic Area (EEA), you have certain data protection rights. 
Prosper Street LLC aims to take reasonable steps to allow you to correct, amend, delete or limit 
the use of your Personal Data.{"\n"}
{"\n"}
If you wish to be informed about what Personal Data we hold about you and if you want it to be removed 
from our systems, please contact us.{"\n"}
{"\n"}
In certain circumstances, you have the following data protection rights:{"\n"}
{"\n"}
{"   "}* <Text style={{...styles.text, fontWeight:"bold"}}>The right to access, update or delete the information we have on you.</Text> Whenever made possible, you can 
access, update or request deletion of your Personal Data directly within your account settings section. 
If you are unable to perform these actions yourself, please contact us to assist you.{"\n"}
{"\n"}
{"   "}* <Text style={{...styles.text, fontWeight:"bold"}}>The right of rectification.</Text> You have the right to have your information rectified if that information 
is inaccurate or incomplete.{"\n"}
{"\n"}
{"   "}* <Text style={{...styles.text, fontWeight:"bold"}}>The right to object.</Text> You have the right to object to our processing of your Personal Data.{"\n"}
{"\n"}
{"   "}* <Text style={{...styles.text, fontWeight:"bold"}}>The right of restriction.</Text> You have the right to request that we restrict the processing of your 
personal information.{"\n"}
{"\n"}
{"   "}* <Text style={{...styles.text, fontWeight:"bold"}}>The right to data portability.</Text> You have the right to be provided with a copy of the information we 
have on you in a structured, machine-readable and commonly used format.{"\n"}
{"\n"}
{"   "}* <Text style={{...styles.text, fontWeight:"bold"}}>The right to withdraw consent.</Text> You also have the right to withdraw your consent at any time where 
Prosper Street LLC relied on your consent to process your personal information.{"\n"}
{"\n"}
Please note that we may ask you to verify your identity before responding to such requests.{"\n"}
{"\n"}
You have the right to complain to a Data Protection Authority about our collection and use of your 
Personal Data. For more information, please contact your local data protection authority in the 
European Economic Area (EEA).{"\n"}
                    </Text>

                    <Text style={styles.header}>
                    Service Providers
                    </Text>

                    <Text style={styles.text}>
We may employ third party companies and individuals to facilitate our Service ("Service Providers"), 
provide the Service on our behalf, perform Service-related services or assist us in analysing how 
our Service is used.{"\n"}
{"\n"}
These third parties have access to your Personal Data only to perform these tasks on our behalf and 
are obligated not to disclose or use it for any other purpose.{"\n"}
                    </Text>

                    <Text style={styles.header}>
                    Links to Other Sites
                    </Text>

                    <Text style={styles.text}>
Our Service may contain links to other sites that are not operated by us. If you click a third party 
link, you will be directed to that third party's site. We strongly advise you to review the Privacy 
Policy of every site you visit.{"\n"}
{"\n"}
We have no control over and assume no responsibility for the content, privacy policies or practices 
of any third party sites or services.{"\n"}
                    </Text>

                    <Text style={styles.header}>
                    Children's Privacy
                    </Text>

                    <Text style={styles.text}>
Our Service does not address anyone under the age of 18 ("Children").{"\n"}
{"\n"}
We do not knowingly collect personally identifiable information from anyone under the age of 18. If 
you are a parent or guardian and you are aware that your Child has provided us with Personal Data, 
please contact us. If we become aware that we have collected Personal Data from children without 
verification of parental consent, we take steps to remove that information from our servers.{"\n"}
                    </Text>

                    <Text style={styles.header}>
                    Changes to This Privacy Policy
                    </Text>

                    <Text style={styles.text}>
We may update our Privacy Policy from time to time. We will notify you of any changes by posting the 
new Privacy Policy on this page.{"\n"}
{"\n"}
We will let you know via email and/or a prominent notice on our Service, prior to the change becoming 
effective and update the "effective date" at the top of this Privacy Policy.{"\n"}
{"\n"}
You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy 
Policy are effective when they are posted on this page.{"\n"}
                    </Text>

                    <Text style={styles.header}>
                    Contact Us
                    </Text>

                    <Text style={styles.text}>
If you have any questions about this Privacy Policy, please contact us:{"\n"}
{"\n"}
By email: support@therecoapp.com{"\n"}
                    </Text>
                </ScrollView>
            </View>
        )
    }
}