export const names = [
    {          
        key: "MARY",
    },
    {
        key: "PATRICIA",
    },
    {
        key: "LINDA",
    },
    {
        key: "BARBARA",
    },
    {
        key: "ELIZABETH",
    },
    {
        key: "JENNIFER",
    },
    {
        key: "MARIA",
    },
    {
        key: "SUSAN",
    },
    {
        key: "MARGARET",
    },
    {
        key: "DOROTHY",
    },
    {
        key: "LISA",
    },
    {
        key: "NANCY",
    },
    {
        key: "KAREN",
    },
    {
        key: "BETTY",
    },
    {
        key: "HELEN",
    },
    {
        key: "SANDRA",
    },
    {
        key: "DONNA",
    },
    {
        key: "CAROL",
    },
    {
        key: "RUTH",
    },
    {
        key: "SHARON",
    },
    {
        key: "MICHELLE",
    },
    {
        key: "LAURA",
    },
    {
        key: "SARAH",
    },
    {
        key: "KIMBERLY",
    },
    {
        key: "DEBORAH",
    },
    {
        key: "JESSICA",
    },
    {
        key: "SHIRLEY",
    },
    {
        key: "CYNTHIA",
    },
    {
        key: "ANGELA",
    },
    {
        key: "MELISSA",
    },
    {
        key: "BRENDA",
    },
    {
        key: "AMY",
    },
    {
        key: "ANNA",
    },
    {
        key: "REBECCA",
    },
    {
        key: "VIRGINIA",
    },
    {
        key: "KATHLEEN",
    },
    {
        key: "PAMELA",
    },
    {
        key: "MARTHA",
    },
    {
        key: "DEBRA",
    },
    {
        key: "AMANDA",
    },
    {
        key: "STEPHANIE",
    },
    {
        key: "CAROLYN",
    },
    {
        key: "CHRISTINE",
    },
    {
        key: "MARIE",
    },
    {
        key: "JANET",
    },
    {
        key: "CATHERINE",
    },
    {
        key: "FRANCES",
    },
    {
        key: "ANN",
    },
    {
        key: "JOYCE",
    },
    {
        key: "DIANE",
    },
    {
        key: "ALICE",
    },
    {
        key: "JULIE",
    },
    {
        key: "HEATHER",
    },
    {
        key: "TERESA",
    },
    {
        key: "DORIS",
    },
    {
        key: "GLORIA",
    },
    {
        key: "EVELYN",
    },
    {
        key: "JEAN",
    },
    {
        key: "CHERYL",
    },
    {
        key: "MILDRED",
    },
    {
        key: "KATHERINE",
    },
    {
        key: "JOAN",
    },
    {
        key: "ASHLEY",
    },
    {
        key: "JUDITH",
    },
    {
        key: "ROSE",
    },
    {
        key: "JANICE",
    },
    {
        key: "KELLY",
    },
    {
        key: "NICOLE",
    },
    {
        key: "JUDY",
    },
    {
        key: "CHRISTINA",
    },
    {
        key: "KATHY",
    },
    {
        key: "THERESA",
    },
    {
        key: "BEVERLY",
    },
    {
        key: "DENISE",
    },
    {
        key: "TAMMY",
    },
    {
        key: "IRENE",
    },
    {
        key: "JANE",
    },
    {
        key: "LORI",
    },
    {
        key: "RACHEL",
    },
    {
        key: "MARILYN",
    },
    {
        key: "ANDREA",
    },
    {
        key: "KATHRYN",
    },
    {
        key: "LOUISE",
    },
    {
        key: "SARA",
    },
    {
        key: "ANNE",
    },
    {
        key: "JACQUELINE",
    },
    {
        key: "WANDA",
    },
    {
        key: "BONNIE",
    },
    {
        key: "JULIA",
    },
    {
        key: "RUBY",
    },
    {
        key: "LOIS",
    },
    {
        key: "TINA",
    },
    {
        key: "PHYLLIS",
    },
    {
        key: "NORMA",
    },
    {
        key: "PAULA",
    },
    {
        key: "DIANA",
    },
    {
        key: "ANNIE",
    },
    {
        key: "LILLIAN",
    },
    {
        key: "EMILY",
    },
    {
        key: "ROBIN",
    },
    {
        key: "PEGGY",
    },
    {
        key: "CRYSTAL",
    },
    {
        key: "GLADYS",
    },
    {
        key: "RITA",
    },
    {
        key: "DAWN",
    },
    {
        key: "CONNIE",
    },
    {
        key: "FLORENCE",
    },
    {
        key: "TRACY",
    },
    {
        key: "EDNA",
    },
    {
        key: "TIFFANY",
    },
    {
        key: "CARMEN",
    },
    {
        key: "ROSA",
    },
    {
        key: "CINDY",
    },
    {
        key: "GRACE",
    },
    {
        key: "WENDY",
    },
    {
        key: "VICTORIA",
    },
    {
        key: "EDITH",
    },
    {
        key: "KIM",
    },
    {
        key: "SHERRY",
    },
    {
        key: "SYLVIA",
    },
    {
        key: "JOSEPHINE",
    },
    {
        key: "THELMA",
    },
    {
        key: "SHANNON",
    },
    {
        key: "SHEILA",
    },
    {
        key: "ETHEL",
    },
    {
        key: "ELLEN",
    },
    {
        key: "ELAINE",
    },
    {
        key: "MARJORIE",
    },
    {
        key: "CARRIE",
    },
    {
        key: "CHARLOTTE",
    },
    {
        key: "MONICA",
    },
    {
        key: "ESTHER",
    },
    {
        key: "PAULINE",
    },
    {
        key: "EMMA",
    },
    {
        key: "JUANITA",
    },
    {
        key: "ANITA",
    },
    {
        key: "RHONDA",
    },
    {
        key: "HAZEL",
    },
    {
        key: "AMBER",
    },
    {
        key: "EVA",
    },
    {
        key: "DEBBIE",
    },
    {
        key: "APRIL",
    },
    {
        key: "LESLIE",
    },
    {
        key: "CLARA",
    },
    {
        key: "LUCILLE",
    },
    {
        key: "JAMIE",
    },
    {
        key: "JOANNE",
    },
    {
        key: "ELEANOR",
    },
    {
        key: "VALERIE",
    },
    {
        key: "DANIELLE",
    },
    {
        key: "MEGAN",
    },
    {
        key: "ALICIA",
    },
    {
        key: "SUZANNE",
    },
    {
        key: "MICHELE",
    },
    {
        key: "GAIL",
    },
    {
        key: "BERTHA",
    },
    {
        key: "DARLENE",
    },
    {
        key: "VERONICA",
    },
    {
        key: "JILL",
    },
    {
        key: "ERIN",
    },
    {
        key: "GERALDINE",
    },
    {
        key: "LAUREN",
    },
    {
        key: "CATHY",
    },
    {
        key: "JOANN",
    },
    {
        key: "LORRAINE",
    },
    {
        key: "LYNN",
    },
    {
        key: "SALLY",
    },
    {
        key: "REGINA",
    },
    {
        key: "ERICA",
    },
    {
        key: "BEATRICE",
    },
    {
        key: "DOLORES",
    },
    {
        key: "BERNICE",
    },
    {
        key: "AUDREY",
    },
    {
        key: "YVONNE",
    },
    {
        key: "ANNETTE",
    },
    {
        key: "JUNE",
    },
    {
        key: "SAMANTHA",
    },
    {
        key: "MARION",
    },
    {
        key: "DANA",
    },
    {
        key: "STACY",
    },
    {
        key: "ANA",
    },
    {
        key: "RENEE",
    },
    {
        key: "IDA",
    },
    {
        key: "VIVIAN",
    },
    {
        key: "ROBERTA",
    },
    {
        key: "HOLLY",
    },
    {
        key: "BRITTANY",
    },
    {
        key: "MELANIE",
    },
    {
        key: "LORETTA",
    },
    {
        key: "YOLANDA",
    },
    {
        key: "JEANETTE",
    },
    {
        key: "LAURIE",
    },
    {
        key: "KATIE",
    },
    {
        key: "KRISTEN",
    },
    {
        key: "VANESSA",
    },
    {
        key: "ALMA",
    },
    {
        key: "SUE",
    },
    {
        key: "ELSIE",
    },
    {
        key: "BETH",
    },
    {
        key: "JEANNE",
    },
    {
        key: "JAMES",
    },
    {
        key: "JOHN",
    },
    {
        key: "ROBERT",
    },
    {
        key: "MICHAEL",
    },
    {
        key: "WILLIAM",
    },
    {
        key: "DAVID",
    },
    {
        key: "RICHARD",
    },
    {
        key: "CHARLES",
    },
    {
        key: "JOSEPH",
    },
    {
        key: "THOMAS",
    },
    {
        key: "CHRISTOPHER",
    },
    {
        key: "DANIEL",
    },
    {
        key: "PAUL",
    },
    {
        key: "MARK",
    },
    {
        key: "DONALD",
    },
    {
        key: "GEORGE",
    },
    {
        key: "KENNETH",
    },
    {
        key: "STEVEN",
    },
    {
        key: "EDWARD",
    },
    {
        key: "BRIAN",
    },
    {
        key: "RONALD",
    },
    {
        key: "ANTHONY",
    },
    {
        key: "KEVIN",
    },
    {
        key: "JASON",
    },
    {
        key: "MATTHEW",
    },
    {
        key: "GARY",
    },
    {
        key: "TIMOTHY",
    },
    {
        key: "JOSE",
    },
    {
        key: "LARRY",
    },
    {
        key: "JEFFREY",
    },
    {
        key: "FRANK",
    },
    {
        key: "SCOTT",
    },
    {
        key: "ERIC",
    },
    {
        key: "STEPHEN",
    },
    {
        key: "ANDREW",
    },
    {
        key: "RAYMOND",
    },
    {
        key: "GREGORY",
    },
    {
        key: "JOSHUA",
    },
    {
        key: "JERRY",
    },
    {
        key: "DENNIS",
    },
    {
        key: "WALTER",
    },
    {
        key: "PATRICK",
    },
    {
        key: "PETER",
    },
    {
        key: "HAROLD",
    },
    {
        key: "DOUGLAS",
    },
    {
        key: "HENRY",
    },
    {
        key: "CARL",
    },
    {
        key: "ARTHUR",
    },
    {
        key: "RYAN",
    },
    {
        key: "ROGER",
    },
    {
        key: "JOE",
    },
    {
        key: "JUAN",
    },
    {
        key: "JACK",
    },
    {
        key: "ALBERT",
    },
    {
        key: "JONATHAN",
    },
    {
        key: "JUSTIN",
    },
    {
        key: "TERRY",
    },
    {
        key: "GERALD",
    },
    {
        key: "KEITH",
    },
    {
        key: "SAMUEL",
    },
    {
        key: "WILLIE",
    },
    {
        key: "RALPH",
    },
    {
        key: "LAWRENCE",
    },
    {
        key: "NICHOLAS",
    },
    {
        key: "ROY",
    },
    {
        key: "BENJAMIN",
    },
    {
        key: "BRUCE",
    },
    {
        key: "BRANDON",
    },
    {
        key: "ADAM",
    },
    {
        key: "HARRY",
    },
    {
        key: "FRED",
    },
    {
        key: "WAYNE",
    },
    {
        key: "BILLY",
    },
    {
        key: "STEVE",
    },
    {
        key: "LOUIS",
    },
    {
        key: "JEREMY",
    },
    {
        key: "AARON",
    },
    {
        key: "RANDY",
    },
    {
        key: "HOWARD",
    },
    {
        key: "EUGENE",
    },
    {
        key: "CARLOS",
    },
    {
        key: "RUSSELL",
    },
    {
        key: "BOBBY",
    },
    {
        key: "VICTOR",
    },
    {
        key: "MARTIN",
    },
    {
        key: "ERNEST",
    },
    {
        key: "PHILLIP",
    },
    {
        key: "TODD",
    },
    {
        key: "JESSE",
    },
    {
        key: "CRAIG",
    },
    {
        key: "ALAN",
    },
    {
        key: "SHAWN",
    },
    {
        key: "CLARENCE",
    },
    {
        key: "SEAN",
    },
    {
        key: "PHILIP",
    },
    {
        key: "CHRIS",
    },
    {
        key: "JOHNNY",
    },
    {
        key: "EARL",
    },
    {
        key: "JIMMY",
    },
    {
        key: "ANTONIO",
    },
    {
        key: "DANNY",
    },
    {
        key: "BRYAN",
    },
    {
        key: "TONY",
    },
    {
        key: "LUIS",
    },
    {
        key: "MIKE",
    },
    {
        key: "STANLEY",
    },
    {
        key: "LEONARD",
    },
    {
        key: "NATHAN",
    },
    {
        key: "DALE",
    },
    {
        key: "MANUEL",
    },
    {
        key: "RODNEY",
    },
    {
        key: "CURTIS",
    },
    {
        key: "NORMAN",
    },
    {
        key: "ALLEN",
    },
    {
        key: "MARVIN",
    },
    {
        key: "VINCENT",
    },
    {
        key: "GLENN",
    },
    {
        key: "JEFFERY",
    },
    {
        key: "TRAVIS",
    },
    {
        key: "JEFF",
    },
    {
        key: "CHAD",
    },
    {
        key: "JACOB",
    },
    {
        key: "LEE",
    },
    {
        key: "MELVIN",
    },
    {
        key: "ALFRED",
    },
    {
        key: "KYLE",
    },
    {
        key: "FRANCIS",
    },
    {
        key: "BRADLEY",
    },
    {
        key: "JESUS",
    },
    {
        key: "HERBERT",
    },
    {
        key: "FREDERICK",
    },
    {
        key: "RAY",
    },
    {
        key: "JOEL",
    },
    {
        key: "EDWIN",
    },
    {
        key: "DON",
    },
    {
        key: "EDDIE",
    },
    {
        key: "RICKY",
    },
    {
        key: "TROY",
    },
    {
        key: "RANDALL",
    },
    {
        key: "BARRY",
    },
    {
        key: "ALEXANDER",
    },
    {
        key: "BERNARD",
    },
    {
        key: "MARIO",
    },
    {
        key: "LEROY",
    },
    {
        key: "FRANCISCO",
    },
    {
        key: "MARCUS",
    },
    {
        key: "MICHEAL",
    },
    {
        key: "THEODORE",
    },
    {
        key: "CLIFFORD",
    },
    {
        key: "MIGUEL",
    },
    {
        key: "OSCAR",
    },
    {
        key: "JAY",
    },
    {
        key: "JIM",
    },
    {
        key: "TOM",
    },
    {
        key: "CALVIN",
    },
    {
        key: "ALEX",
    },
    {
        key: "JON",
    },
    {
        key: "RONNIE",
    },
    {
        key: "BILL",
    },
    {
        key: "LLOYD",
    },
    {
        key: "TOMMY",
    },
    {
        key: "LEON",
    },
    {
        key: "DEREK",
    },
    {
        key: "WARREN",
    },
    {
        key: "DARRELL",
    },
    {
        key: "JEROME",
    },
    {
        key: "FLOYD",
    },
    {
        key: "LEO",
    },
    {
        key: "ALVIN",
    },
    {
        key: "TIM",
    },
    {
        key: "WESLEY",
    },
    {
        key: "GORDON",
    },
    {
        key: "DEAN",
    },
    {
        key: "GREG",
    },
    {
        key: "JORGE",
    },
    {
        key: "DUSTIN",
    },
    {
        key: "PEDRO",
    },
    {
        key: "DERRICK",
    },
    {
        key: "DAN",
    },
    {
        key: "LEWIS",
    },
    {
        key: "ZACHARY",
    },
    {
        key: "COREY",
    },
    {
        key: "HERMAN",
    },
    {
        key: "MAURICE",
    },
    {
        key: "VERNON",
    },
    {
        key: "ROBERTO",
    },
    {
        key: "CLYDE",
    },
    {
        key: "GLEN",
    },
    {
        key: "HECTOR",
    },
    {
        key: "SHANE",
    },
    {
        key: "RICARDO",
    },
    {
        key: "SAM",
    },
    {
        key: "RICK",
    },
    {
        key: "LESTER",
    },
    {
        key: "BRENT",
    },
    {
        key: "RAMON",
    },
    {
        key: "CHARLIE",
    },
    {
        key: "TYLER",
    },
    {
        key: "GILBERT",
    },
    {
        key: "GENE",
    }
]
