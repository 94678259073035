import React, {Component} from 'react';
import { ScrollView, View, Image, Text, TouchableOpacity, Dimensions} from 'react-native';
import './App.css';
import {names} from './names';
import PrivacyPolicy from './PrivacyPolicy';
import {Link, BrowserRouter} from 'react-router-dom'

const padding = 10;

export default class App extends Component {
  state = {
    name: "",
    privacyPolicy: false,
  }

  getRandomInt = (max) => {
    return Math.floor(Math.random() * Math.floor(max));
  }

  setNewName = () => {
    var rand = this.getRandomInt(400);
    var name = names[rand].key.toLowerCase();
    var uppercaseFirstLetter = name.charAt(0).toUpperCase();
    name = name.slice(1);
    name = uppercaseFirstLetter + name;
    this.setState({name:name});
  }

  componentDidMount = () => {
    this.setNewName();
  }

  render() {
    const {name, privacyPolicy} = this.state
    const imageWidth = Math.min(Dimensions.get('window').width-(2*padding), 400)
    if(privacyPolicy) {
      return (
        <PrivacyPolicy onExit={() => this.setState({privacyPolicy:false})}/>
      )
    }
    return (
      <BrowserRouter>
        <ScrollView 
          style={{flex:1, margin:padding}}
          contentContainerStyle={{
            backgroundColor:'black',
            alignContent:'center',
            justifyContent:'center',
            alignItems:'center',
            flex:1,
            height:'100%',
            border:0,
          }}>
          <Text style={{fontSize:30, fontWeight:'normal', color:'white', textAlign:'center'}}>
            No one asked for your opinion, <TouchableOpacity
              onPress={() => this.setNewName()}
            >
              <Text style={{fontWeight:'bold', color:'#c044ff', textDecorationLine:'underline'}}>{name}</Text>
            </TouchableOpacity>
          </Text> 
          <Image source={require("./logo.png")} style={{height:imageWidth, width:imageWidth, margin:20}} />
          <Text style={{fontSize:50, fontWeight:'bold', color:'white', textAlign:'center'}}>
            The Reco App
          </Text>
          <Text style={{fontSize:30, color:'white', textAlign:'center', margin:5}}>
            Get recommendations from the people you trust.
          </Text>
          <Text style={{fontSize:30, fontWeight:'normal', color:'white', textAlign:'center'}}>
            See it on the <a href="https://apps.apple.com/us/app/the-reco-app/id1458631129"
            style={{fontWeight:'bold', color:'#c044ff', textDecorationLine:'underline'}}>App Store</a> and
            the <a href="https://play.google.com/store/apps/details?id=com.prosperstreet.reco"
            style={{fontWeight:'bold', color:'#c044ff', textDecorationLine:'underline'}}>Play Store</a></Text>
          <View style={{alignSelf:'center', border:0, padding:10}}>
            <Text style={{fontSize:20, fontWeight:'normal', color:'gray', textAlign:'center', margin:5}}>
              Created by Prosper Street LLC, contact us at support@therecoapp.com
            </Text>
            <TouchableOpacity
              style={{alignContent:'center', alignItems:'center', justifyContent:'center', margin:5}}
              onPress={() => this.setState({privacyPolicy:true})}
            >
              <Text style={{fontWeight:'normal', color:'gray', textDecorationLine:'underline', fontSize:20}}>Privacy Policy</Text>
            </TouchableOpacity> 
          </View>
          
        </ScrollView>
      </BrowserRouter>
    );
  }
}


/*<a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a> */
